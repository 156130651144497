// Rendered by render_template in app/assets/javascripts/maps/maps.js.coffee

import * as React from 'react'

export interface DetailsForFinderProps {
  id: any
  name: string
  link_to_house_church: boolean
  show_state: boolean
  state: string
  leaders: any[]
  lat: any
  lon: any
  include_add_to_route: boolean
}

export function DetailsForFinder({
  id,
  name,
  link_to_house_church,
  show_state,
  state,
  coach,
  leaders,
  include_add_to_route,
  lat,
  lon,
  ...props
}: DetailsForFinderProps) {
  return (
    <div className="simple_church details_for_finder_template">
      <h3 className="name">
        {link_to_house_church ? (
          <a href={`/simple_churches/${id}`} className="dont_look_like_link" target="_blank">
            {name}
          </a>
        ) : (
          name
        )}
      </h3>

      {show_state && (state == 'building_core_team' || state == 'phase1') && (
        <p>
          This simple church is building their CORE missionary team. Once they have officially launched, their contact
          information will be available.
        </p>
      )}
      {show_state && state == 'phase2' && (
        <p>
          This simple church is currently completing PhaseTWO Training. Once they have officially launched, their
          contact information will be available.
        </p>
      )}
      {show_state && state == 'pending_launch_date' && (
        <p>
          This simple church will be launching soon. Once they have officially launched, their contact information will
          be available.
        </p>
      )}
      {state == 'launched' && (
        <p>
          For security reasons this marker only shows an approximate location. Please contact the CORE team for their
          address, the time they gather, and other information.
        </p>
      )}
      {coach && (
        <>
          <div style={{ fontSize: '14px' }}>Coach:</div>
          <table className="coach_contact_info_table table table-striped table-condensed">
            <tbody>
              <tr>
                <td className="name">{coach.name}</td>
                <td className="phone">{coach.phone}</td>
                <td className="email">{coach.email}</td>
              </tr>
            </tbody>
          </table>
        </>
      )}
      {leaders && leaders.length > 0 && (
        <>
          <div style={{ fontSize: '14px' }}>CORE team:</div>
          <table className="contact_info_table table table-striped table-condensed">
            <tbody>
              {leaders.map((leader, i) => (
                <tr key={i}>
                  <td className="name">{leader.name}</td>
                  <td className="phone">{leader.phone}</td>
                  <td className="email">{leader.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
      {include_add_to_route && <div className="add_to_route_button btn btn-small">Add to route</div>}
      <a className="contact_button btn btn-small" href={`/house_churches/${id}/contact_requests/new`} target="_blank">
        Contact »
      </a>
      <div className="id hidden">{id}</div>
      <div className="geolocation hide" data-lat={lat} data-lon={lon}></div>
    </div>
  )
}

<!-- svelte-ignore a11y-no-onchange -->
<select on:input on:blur on:change bind:value {...$$restProps}>
  <!-- We don't include a null/blank option because we want them to pick one -->
  {#each denominations || [] as denomination}
    {#if denomination === "-"}
      <option disabled="disabled" value="divider">────────────────</option>
    {:else}
      <option value={denomination}>{denomination}</option>
    {/if}
  {:else}
    <option value="">Loading...</option>
  {/each}
</select>

<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { onMount } from 'svelte';
import axios from 'axios';
// I would have just imported directly from a JSON file, but that would make it harder to change. (It would require rebuilding the assets, which currently is a slow process.) So we'll fetch it from Rails, which lets us more easily change the data without rebuilding the assets.
//import denominations from '../../../../../../db/data/denominations.json'
export let value;
let denominations = [];
onMount(() => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield axios.get(`/denominations`);
    denominations = yield result.data;
}));
</script>

// Named FormLib, because this had an import error when I tried naming it Form.ts.
import axios from 'axios'

import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'

import { normalizePhoneNumber } from 'common/phone_numbers/normalizePhoneNumber'
import { defaultCountryCode } from 'common/phone_numbers'
import { HouseChurchRegistrationForm_PhoneInputWithinForm } from '@react/forms/PhoneInput/HouseChurchRegistrationForm_PhoneInputWithinForm'

import { FORM_ERROR } from 'final-form'
import { flattenErrors } from 'common/flattenErrors'

declare global {
  interface Window {
    show_user_form: boolean
  }
}

// From backend
export interface PartOfHouseChurchResponse_HouseChurch {
  type: 'HouseChurchRegistration' | 'SimpleChurch'
  name?: string
  address_city_state_country?: string
}
export interface PartOfHouseChurchResponse {
  house_church?: PartOfHouseChurchResponse_HouseChurch
}

// From user input (frontend)
// Must match shape that Create interaction on app/controllers/house_church_registrations_controller.rb is expecting
// Note: This currently isn't really checked against. Is there some way to get final-form to use this typing for its form.values?
export interface Payload {
  house_church_registration: Registration
  subscriptions?: User[]
}
export interface Registration {
  registrant_attributes: User
  house_church_attributes: HouseChurch

  duration_of_membership?: string
  is_user_a_leader?: boolean
}

export interface HouseChurch {
  name: string
  address_attributes: Address
}
export interface Address {
  address?: string
  city?: string
  state?: string
  postal_code?: string
  country_code?: string
}

//══════════════════════════════════════════════════════════════════════════════════════════════════
// { Validations

import { readable, derived, writable, get } from 'svelte/store'
import { required, checkValidEmail, composeValidators, simpleMemoize, requiredWithMessage } from 'common/final-form'

//──────────────────────────────────────────────────────────────────────────────────────────────────

// Please enter which units go with the number, for example "2 months" or "2 years"
const checkValidDuration = (value) => null

//──────────────────────────────────────────────────────────────────────────────────────────────────
// checkForDuplicateOnEmail

import debounce from 'debounce-promise'
import { ErrorSharp } from '@material-ui/icons'
import { User } from 'common/types'

// let duplicate_on_email: PartOfHouseChurchResponse = null
export let duplicate_on_email = writable<PartOfHouseChurchResponse>(null)

export const duplicateOnEmailMessage = 'House church already registered!'
const checkForDuplicateOnEmail = async (value) => {
  try {
    if (!value) {
      return
    }

    // console.log('check_for_duplicate_on_email: checking', value)
    const url = '/users/part_of_house_church.json'
    let params = new URLSearchParams({
      email: value,
    })
    const result = await axios.get(url, { params })
    const data = result.data as PartOfHouseChurchResponse

    if (data && data.house_church) {
      duplicate_on_email.set(data)
      console.log('=> setting', get(duplicate_on_email))
      return duplicateOnEmailMessage
    } else {
      // console.log('=> clearing')
      duplicate_on_email.set(null)
    }
  } catch (error) {
    console.log('caught error:', error)
  }
}

const duplicateOnEmailDebounced = simpleMemoize(debounce(checkForDuplicateOnEmail, 1000))

export const validateEmail = composeValidators(required, checkValidEmail, duplicateOnEmailDebounced)

//──────────────────────────────────────────────────────────────────────────────────────────────────
// checkForDuplicateOnHouseChurchName

// let duplicate_on_house_church_name: PartOfHouseChurchResponse = null
export let duplicate_on_house_church_name = writable<PartOfHouseChurchResponse>(null)

// const duplicateOnHouseChurchNameMessage = 'House church name exists in database'
const checkForDuplicateOnHouseChurchName = async (value) => {
  try {
    if (!value) {
      return
    }
    const url = '/house_churches/find_by_name.json'
    const params = new URLSearchParams({
      name: value,
    })
    const result = await axios.get(url, { params })
    const data = result.data as PartOfHouseChurchResponse
    if (data && data.house_church) {
      console.log('data=', data)
      duplicate_on_house_church_name.set(data)
      // Add as warning? duplicateOnHouseChurchNameMessage
    } else {
      duplicate_on_house_church_name.set(null)
    }
  } catch (error) {
    console.log('caught error:', error)
  }
}

const duplicateOnHouseChurchNameDebounced = simpleMemoize(debounce(checkForDuplicateOnHouseChurchName, 1000))
// const duplicateOnHouseChurchNameDebounced = checkForDuplicateOnHouseChurchName

export const validateHouseChurchName = composeValidators(required, duplicateOnHouseChurchNameDebounced)

export const subscribeRequired = requiredWithMessage(
  `In order to add your house church to the map, you must be subscribed to the Simple Church newsletter so that we can stay in touch with you about news relevant to Simple Church.`
)

// } Validations

//══════════════════════════════════════════════════════════════════════════════════════════════════

export const submit = async (payload: Payload) => {
  console.log('HouseChurchRegistrationForm: Saving form…')
  console.log('payload=', payload)
  // payload = {fake: 'fake'}
  // this.topLevelErrors = {}
  let response
  try {
    const axiosResponse = await axios.post('/house_church_registrations.json', payload)
    response = axiosResponse.data
  } catch (error) {
    response = await handleResponseErrors(error)
  } finally {
    // this.submitting = false
  }
  if (!response) {
    return
  }
  if (response.redirect_to) {
    window.location.href = response.redirect_to
  } else if (response.errors) {
    console.log('Errors while saving form:', response.errors)
    return response.errors
  }
  return null
}

//==================================================================================================

// If there are validation errors from the server, return as {errors: ...}
async function handleResponseErrors(error) {
  console.log('submit: caught', error)
  const axiosResponse = error.response
  console.log('data:', axiosResponse.data)
  const response = axiosResponse.data
  const errors = response.errors
  console.log('errors:', errors)
  if (errors) {
    return {
      errors,
    }
  } else {
    if (axiosResponse.statusText) {
      alert(`Server responded with: ${axiosResponse.statusText}. Please try again later.`)
    } else {
      alert(
        `An unknown error occurred. Please try again later. If this occurs again, please let us know about the problem.`
      )
    }
    throw error
  }
}

//─────────────────────────────────────────────────────────────────────────────────────────────────
// Debug/dev tools

class Utils {
  // We *don't* show user form if logged in
  // (We need getter here because it won't be defined yet at the time this module is compiled.)
  get show_user_form() {
    return (window as any).show_user_form
  }
}
export const utils = new Utils()

export const debugFillIn = (form, rootElement) => {
  const rand = Math.floor(Math.random() * 999999999)
  let change = (name, value) => form.change(`house_church_registration.${name}`, value)

  if (utils.show_user_form) {
    change('registrant_attributes.email', `test+${rand}@example.com`)
    change('registrant_attributes.given_name', 'Tester')
    change('registrant_attributes.family_name', 'Last')
  }
  change('duration_of_membership', '7 months')
  change('is_user_a_leader', 'true')
  change('house_church_attributes.name', 'My House Church')
  change('house_church_attributes.address_attributes.country_code', 'US')
  change('house_church_attributes.address_attributes.address', '123 Test St.')
  change('house_church_attributes.address_attributes.city', 'City')
  setTimeout(() => change('house_church_attributes.address_attributes.state', 'WA'), 0)
  // Workaround for: the previous change gets lost/reverted/reset when StateSelect loads states for selected country.
  setTimeout(() => change('house_church_attributes.address_attributes.state', 'WA'), 2000)
  change('house_church_attributes.address_attributes.postal_code', '99324')
  form.change('subscribe_to_newsletter', true)

  //form.change('subscriptions', [
  // {
  //   given_name: 'SubscriberFirst',
  //   family_name: 'SubscriberLast',
  //   email: 'subscriber@example.com',
  // },
  //)

  // this.renderPhoneInput()
  const submit = rootElement.querySelector('button[type=submit]')
  const firstInput = rootElement.querySelector('input')
  firstInput.focus()
  setTimeout(() => document.getElementById('continue_registration_button').click(), 2000)

  if (false && submit) {
    submit.scrollIntoView()
    setTimeout(() => submit.focus())
  }
}

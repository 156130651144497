<!-- svelte-ignore unused-export-let -->
<RailsForm action={url} on:submit={(event) => onSubmit(event, form)} method="PATCH" bind:formEl>
  {#if picking_coach_scenario == 'optionally_picking_new_coach'}
    <OptionallyPicking
      {saved_coach}
      bind:confirmation={optionally_picking_confirmation}
      on:input={handle_change_optionally_picking_confirmation}
    />
  {:else}
    <NeedfullyPicking simple_church={saved_simple_church} {coach_count} />
  {/if}

  {#if show_list}
    <div class="centered">
      <h2>{coach_select_title}</h2>
    </div>

    <div class="coach-list-container centered">
      <Field
        name="simple_church[coach_id]"
        let:field
        validate={ifValidationsEnabled(requiredWithMessage('Please select one of the coaches below.'))}
      >
        <FieldErrors {field} when="submitFailed" />
        <input type="hidden" {...field.input} />

        <!-- https://material.io/components/lists/web explains the difference between selected and activated, among other things -->
        <List class="coach-list" threeLine avatarList singleSelection={editable} nonInteractive={!editable}>
          {#each coach_options as coach, i}
            {#if i === 0}
              <Separator />
            {/if}
            <Item
              on:SMUI:action={() => {
                if (editable) {
                  field.handlers.change(coach.id)
                }
              }}
              selected={selectedCoachId === coach.id}
              activated={!editable && selectedCoachId === coach.id}
            >
              <Graphic data-i={i}>
                <span class="text">
                  {coach.name
                    .split(' ')
                    .map((val) => val.substring(0, 1))
                    .join('')}
                </span>
              </Graphic>
              <Text>
                <PrimaryText>{coach.name}</PrimaryText>
                {#if coach.primary_language || coach.other_languages.length > 0}
                  <SecondaryText>
                    Fluent in {#if coach.primary_language}<b>{coach.primary_language}</b
                      >{/if}{#if coach.primary_language && coach.other_languages.length > 0}, {/if}
                    {#if coach.other_languages.length > 0}{coach.other_languages.join(', ')}{/if}</SecondaryText
                  >
                {/if}
                <SecondaryText>
                  {#if coach.n_coaching == 0}
                    Not currently coaching any CORE teams.
                  {:else}
                    Currently coaching <b>{coach.n_coaching} other</b> CORE {coach.n_coaching == 1 ? 'team' : 'teams'}
                  {/if}
                </SecondaryText>
                <SecondaryText>
                  <!-- Has been a missionary at <b>{coach.missionary_at.name}</b> for <b>{coach.missionary_for}</b> -->
                  <b>{coach.missionary_for}</b> at {coach.missionary_at.name}
                </SecondaryText>
                <SecondaryText>
                  Located in <b>{coach.location}</b>
                </SecondaryText>
              </Text>
              <!-- <Meta class="material-icons">info</Meta> -->
            </Item>
            <Separator />
          {/each}
        </List>
      </Field>
    </div>

    {#if selectedCoach}
      {#if saved_coach_picked_by_user_name || has_changed}
        <p>
          {coach_picked_by_user_name}
          {coach_picked_by_user_name == 'You' ? 'have' : 'has'} selected <b>{selectedCoach.name}</b> to be your team's coach.
        </p>
      {/if}
      {#if editable && has_changed}
        <p class="">
          As soon as you press the button below, we will let <b>{selectedCoach.given_name}</b> know that you have picked {selectedCoach.him_or_her}
          and ask
          {selectedCoach.him_or_her} to give you a call to introduce {selectedCoach.him_or_her}self.
        </p>

        <p class="">Each year you will have the option (it is not required) to pick a different coach, if you wish.</p>
      {:else}
        <p class="">
          {#if can_optionally_pick_new_coach}
            <!-- As of {can_optionally_pick_new_coach_on}, you now have the option (it is not required) to pick a different
            coach from the list above, if you wish. This change needs to happen by {#if true}February 28{:else}January
              31{/if}. -->
          {:else}
            On {can_optionally_pick_new_coach_on}, you will have the option (it is not required) to pick a different
            coach, if you wish.
          {/if}
        </p>
      {/if}
    {/if}
  {/if}

  <ContinueButton>
    {#if editable && saved_coach && !has_changed}
      Keep {saved_coach.name} as our coach
    {:else if editable && selectedCoach}
      Pick {selectedCoach.name} to be our coach
    {:else}
      <!-- For example, if spouse completes this step of invitation response before I do -->
      Continue »
    {/if}
  </ContinueButton>
</RailsForm>

{#if utils.debug || utils.dev}
  <div class="debug">picking_coach_scenario: {picking_coach_scenario}</div>
  <div class="debug">editable: {editable}</div>
  <div class="debug">Selected: {selectedCoachId}: {JSON.stringify(selectedCoach, null, 2)}</div>
  <pre class="debug">state.values: {JSON.stringify(state.values, null, 2)}</pre>
  <pre class="debug">state: {JSON.stringify(state, null, 2)}</pre>
{/if}

<script lang="ts">import { onMount } from 'svelte';
const hexagonGreen = require('images/hexagon-green.png');
const hexagonBlue = require('images/hexagon-blue.png');
const hexagonPurple = require('images/hexagon-purple.png');
const hexagonOrange = require('images/hexagon-orange.png');
const hexagonRed = require('images/hexagon-red.png');
const backgrounds = [hexagonGreen, hexagonBlue, hexagonPurple, hexagonOrange, hexagonRed];
// svelte-material-ui
import '@smui/common/internal';
import '@smui/textfield';
import List, { Item, Graphic, Text, PrimaryText, SecondaryText, Separator } from '@smui/list';
// final-form
import { createForm, Field } from 'svelte-final-form';
import 'final-form-focus';
import { ifValidationsEnabled, requiredWithMessage, setSubmitErrors } from 'common/final-form';
import { FieldErrors } from '@svelte/common/form/svelte-final-form/';
import { utils } from '../../../common/utils';
import { RailsForm } from '@svelte/common/form';
import '@svelte/common/form/focusFirstError';
import ContinueButton from '../ContinueButton.svelte';
import NeedfullyPicking from './NeedfullyPicking.svelte';
import OptionallyPicking from './OptionallyPicking.svelte';
import 'common/types';
//════════════════════════════════════════════════════════════════════════════════════════════════
// Inputs
export let url;
export let picking_coach_scenario;
$: editable = !!picking_coach_scenario;
$: show_list =
    picking_coach_scenario == 'optionally_picking_new_coach'
        ? optionally_picking_confirmation == 'pick_a_new_coach'
        : true;
export let can_optionally_pick_new_coach; // $:
export let can_optionally_pick_new_coach_on = undefined;
export let coach_options = [];
// $: console.log(coach_options)
export let submitErrors = {};
// Current state from the database
export let saved_simple_church;
export let saved_coach = undefined; // initialValues.simple_church.coach_id will be set to this
export let coach_count;
export let saved_coach_picked_by_user;
export let saved_coach_picked_by_user_name; // state.values.simple_church.coach_picked_by_user.given_name
let formEl = undefined;
let optionally_picking_confirmation;
let initialCoachId;
let selectedCoachId;
$: coach_select_title = saved_simple_church.is_picking_first_coach ? 'Select Coach' : 'Pick A New Coach';
//════════════════════════════════════════════════════════════════════════════════════════════════
// { final-form
let initialValues = {
    simple_church: {
        coach_id: saved_coach === null || saved_coach === void 0 ? void 0 : saved_coach.id,
    },
};
const onSubmit = (event, form) => {
    // I wish this had a useful (valid/invalid) return value that we could check.
    form.submit();
    if (form.getState().submitFailed) {
        focusList();
        event.preventDefault();
    }
    else {
        // Allow form to submit normally to server
    }
};
const onValidSubmit = (values) => {
    // console.log('valid', JSON.stringify(values, undefined, 2))
    // console.log('valid', form.getState())
};
const [form, stateStore] = createForm({
    onSubmit: onValidSubmit,
    initialValues: initialValues,
    mutators: {
        setSubmitErrors,
    },
});
form.mutators.setSubmitErrors(Object.assign({}, submitErrors));
$: state = $stateStore;
// $: console.log(state.values)
$: initialCoachId = initialValues.simple_church.coach_id;
$: selectedCoachId = state.values.simple_church.coach_id;
// Can't do this because coach_options may not even _include_ a coach matching initialCoachId. Have to use saved_coach instead.
// $: initialCoach = coach_options.find((coach) => initialCoachId === coach.id) // AKA old_coach, but only if you change from it
// In the case where selectedCoachId (coming from initialCoachId) is a coach that is not in the list of options, we fall back to using saved_coach
$: selectedCoach = coach_options.find((coach) => selectedCoachId === coach.id) || saved_coach;
$: has_changed = selectedCoachId && selectedCoachId != initialCoachId;
$: coach_picked_by_user_name = has_changed ? 'You' : saved_coach_picked_by_user_name;
// } final-form
//════════════════════════════════════════════════════════════════════════════════════════════════
function focusList() {
    var _a, _b;
    ;
    (_a = (formEl.querySelector('.mdc-list-item.mdc-list-item--selected') || formEl.querySelector('.mdc-list-item'))) === null || _a === void 0 ? void 0 : _a.focus();
    // document.querySelector('#main .intro').scrollIntoView()
    (_b = document.querySelector('.progresss_header')) === null || _b === void 0 ? void 0 : _b.scrollIntoView();
}
onMount(() => {
    focusList();
});
function handle_change_optionally_picking_confirmation(event) {
    if (event.target.value === 'keep_current') {
        form.reset();
    }
}
</script>

<style lang="scss">.coach-list-container {
  margin-bottom: 1em;
}

.centered {
  flex-direction: column;
}

:global(.coach-list) {
  min-width: 67%;
}
:global(.coach-list) :global(.mdc-list-item__graphic) {
  display: inline-flex;
  margin-right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 1.5rem;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  background-color: #aaa;
}
:global(.coach-list) :global(.mdc-list-item__graphic) :global(.text) {
  justify-self: center;
  color: white;
  text-shadow: rgba(34, 34, 34, 0.7490196078) 1px 1px 5px;
}
:global(.coach-list) :global(.mdc-list-item__graphic[data-i="0"]) {
  background-color: #1bb133;
}
:global(.coach-list) :global(.mdc-list-item__graphic[data-i="0"]) :global(.text) {
  text-shadow: rgba(216, 216, 216, 0.45) 1px 1px 5px;
}
:global(.coach-list) :global(.mdc-list-item__graphic[data-i="1"]) {
  background-color: #0f3efb;
}
:global(.coach-list) :global(.mdc-list-item__graphic[data-i="2"]) {
  background-color: #8a2688;
}
:global(.coach-list) :global(.mdc-list-item__graphic[data-i="3"]) {
  background-color: #ef8622;
}
:global(.coach-list) :global(.mdc-list-item__graphic[data-i="4"]) {
  background-color: #fb0d1b;
}
:global(.coach-list.smui-list--three-line) :global(.mdc-list-item) {
  height: auto;
  padding-bottom: 1em;
  background-color: rgba(150, 150, 150, 0.0392156863);
}
:global(.mdc-list-item--selected:before) {
  opacity: 0.08;
  background-color: var(--mdc-theme-primary, #0072c6);
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  z-index: 1;
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  content: "";
}
:global(.mdc-list-item__primary-text) {
  margin-bottom: -16px;
}</style>
